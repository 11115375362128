export const publicApi = async ({
  url,
  payload,
  method,
  headers = {},
}: {
  url: RequestInfo | URL;
  payload?: any;
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  headers?: { [key in string]: any };
}): Promise<{ code: number; data?: any; errors?: { [key in string]: string }; message?: string }> => {
  const res = await fetch(url, {
    ...(method && { method }),
    ...(payload && { body: JSON.stringify(payload) }),
    headers: { "Content-Type": "application/json", ...headers },
  });

  try {
    const val = await res.json();

    return val;
  } catch (err) {
    console.error(err);
    return { code: 500 };
  }
};

export const formDataApi = async ({
  url,
  payload,
  headers,
}: {
  url: RequestInfo | URL;
  payload: BodyInit;
  headers?: { [key in string]: any };
}) => {
  try {
    const res = await fetch(`${url}`, { method: "POST", body: payload, headers });

    const val = await res.json();

    const { files } = val;

    return files ?? [];
  } catch (err) {
    console.error(err);
  }
};
