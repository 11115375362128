/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import axios from "axios";

import { useAlert, useNav, usePdf } from "../../providers";
import { publicApi } from "../../services";
import { InputFile, Loader, ShowResult } from "../common";

const Merge = () => {
  const { addAlert } = useAlert();

  const { activeButton, setActiveButton, setAcceptFileType } = useNav();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any[]>([]);

  useEffect(() => {
    setActiveButton({ name: "Merge", value: "merge", tooltip: "Merges two or more .pdf files" });
    setAcceptFileType({ "pdf/.pdf": [".pdf"] });
    setSelectedFiles([]);
    setResponseData([]);
  }, []);

  const handleUpload = async () => {
    setIsLoading(true);
    const urls = await Promise.all(
      selectedFiles.map(async (uploadedFile) => {
        const formData = new FormData();

        formData.append("file", uploadedFile);

        let res = await axios.post(`${process.env.REACT_APP_BUCKET_URL}/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        return res.data;
      })
    );

    try {
      const { code, data, message } = await publicApi({
        url: `${process.env.REACT_APP_FLASK_URL}/${activeButton.value}`,
        payload: {
          files: urls,
        },
        method: "POST",
      });

      if (code < 300) {
        message && addAlert({ message, type: "success" });
      } else {
        message && addAlert({ message, type: "error" });
      }

      setResponseData(data);
      setSelectedFiles([]);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  return (
    <>
      {/* <MergeHeader mergeFile={mergeFile} setMergeFile={setMergeFile} /> */}

      <InputFile isSingleFileAccept={false} isInputFileListVisible={true} />

      <Stack sx={{ flex: 1, justifyContent: "center" }} />

      <ShowResult responseData={responseData} />

      <Loader isLoading={isLoading} />

      <Stack sx={{ justifyContent: "flex-end", flexFlow: "wrap-reverse" }}>
        <Button
          variant="contained"
          sx={{
            width: 120,
            height: 80,
            fontSize: 22,
            fontWeight: 900,
            px: 10,
            bgcolor: "rgb(5, 80, 255)",
            color: "white",
            borderRadius: 6,
          }}
          onClick={handleUpload}
          disabled={!(selectedFiles.length && selectedFiles.length > 1) || isLoading}
        >
          {activeButton.name}
        </Button>
      </Stack>
    </>
  );
};

export default Merge;
