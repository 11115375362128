/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from "react";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { Download } from "@mui/icons-material";

import { IDownloadFile } from "./types";

interface IShowResultProps {
  responseData: IDownloadFile[];
}

const ShowResult: FC<IShowResultProps> = ({ responseData }) => {
  const downloadFile = async (item: IDownloadFile) => {
    try {
      const response = await fetch(item.url, {
        method: "GET",
        headers: {
          ...(item.type === "jpg" && { "Content-Type": "image/jpg" }),
          ...(item.type === "pdf" && { "Content-Type": "application/pdf" }),
        },
      });
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = item.name; // Set the name of the file
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(blobUrl); // Cleanup
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <>
      {Array.isArray(responseData) ? (
        <>
          <Stack direction="row" flexWrap="wrap" gap={4}>
            {responseData.map((item, index) => {
              return (
                <Paper
                  key={index}
                  sx={{ textAlign: "center", userSelect: "none", position: "relative", cursor: "pointer" }}
                  elevation={4}
                  onClick={() => downloadFile(item)}
                >
                  {item.type === "pdf" && (
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: 3,
                        marginBottom: 1,
                        marginLeft: 3,
                        marginRight: 1,
                        overflowWrap: "break-word",
                        maxWidth: "90%", // Ensures it wraps within the card width
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        fontSize: 12, // Adjust font size for long names
                      }}
                    >
                      {item.name}
                    </Typography>
                  )}
                  <Box sx={{ backgroundImage: `url(${item.url})`, width: 122, height: 158, backgroundSize: "cover" }} />
                  <IconButton aria-label="delete" onClick={() => {}}>
                    <Download />
                  </IconButton>
                </Paper>
              );
            })}
          </Stack>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ShowResult;
