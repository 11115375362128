import { Routes, Route } from "react-router-dom";

import { publicRoutes } from "./constants";

const MainRoutes = () => {
  return (
    <Routes>
      {/* PUBLIC ROUTE */}
      {publicRoutes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  );
};

export default MainRoutes;
