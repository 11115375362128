/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import axios from "axios";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { PDFDocumentProxy } from "pdfjs-dist";

import { useAlert, useNav, usePdf } from "../../providers";
import { publicApi } from "../../services";
import InsertHeader from "./InsertHeader";
import InsertFooter from "./InsertFooter";
import { IFile, Loader, ShowResult } from "../common";
import InsertFile from "./InsertFile";
import PageRangeOptions from "./PageRangeOptions";

const Insert = () => {
  const { addAlert } = useAlert();
  const { activeButton, setActiveButton, setAcceptFileType } = useNav();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const [mainInsertFile, setMainInsertFile] = useState<IFile | null>(null);
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const [totalMainPageCount, setTotalMainPageCount] = useState<number>(0);
  const [totalSecondaryPageCount, setTotalSecondaryPageCount] = useState<number>(0);
  const [selectedInsertOption, setSelectedInsertOption] = useState<string>("Whole Document");
  const [startPage, setStartPage] = useState<number>(0);
  const [endPage, setEndPage] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any[]>([]);

  useEffect(() => {
    setActiveButton({
      name: "Insert",
      value: "insert",
      tooltip: "Inserts a PDF document into another .pdf file at a specific index.",
    });
    setAcceptFileType({ "pdf/.pdf": [".pdf"] });
    setSelectedFiles([]);
    setResponseData([]);
  }, []);

  useEffect(() => {
    if (selectedFiles.length) {
      setStartPage(1);
      setEndPage(1);
      (async () => {
        try {
          const loadingTask = pdfjsLib.getDocument(selectedFiles[0].preview);
          const pdf: PDFDocumentProxy = await loadingTask.promise;

          setTotalSecondaryPageCount(pdf.numPages);
        } catch (error) {
          console.error("Error rendering thumbnails:", error);
        }
      })();
    }
  }, [selectedFiles]);

  const handleUpload = async () => {
    setIsLoading(true);
    if (!mainInsertFile) {
      return;
    }

    const urls = await Promise.all(
      selectedFiles.map(async (uploadedFile) => {
        const formData = new FormData();

        formData.append("file", uploadedFile);

        let res = await axios.post(`${process.env.REACT_APP_BUCKET_URL}/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        return res.data;
      })
    );

    const formData = new FormData();
    formData.append("file", mainInsertFile);
    const insert_file = await axios.post(`${process.env.REACT_APP_BUCKET_URL}/upload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    try {
      const { code, data, message } = await publicApi({
        url: `${process.env.REACT_APP_FLASK_URL}/${activeButton.value}`,
        payload: {
          files: urls,
          selectedPage,
          insert_file: [insert_file.data],
          startPage,
          endPage,
          selectedInsertOption,
        },
        method: "POST",
      });

      if (code < 300) {
        message && addAlert({ message, type: "success" });
      } else {
        message && addAlert({ message, type: "error" });
      }

      setResponseData(data);
      setSelectedFiles([]);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Stack direction={{ sx: "column", md: "row" }} justifyContent="space-between">
        <Box>
          <InsertHeader mainInsertFile={mainInsertFile} setMainInsertFile={setMainInsertFile} />
          <InsertFile isSingleFileAccept={true} isInputFileListVisible={true} />
        </Box>

        <PageRangeOptions
          totalSecondaryPageCount={totalSecondaryPageCount}
          selectedInsertOption={selectedInsertOption}
          setSelectedInsertOption={setSelectedInsertOption}
          startPage={startPage}
          setStartPage={setStartPage}
          endPage={endPage}
          setEndPage={setEndPage}
        />
      </Stack>

      <Stack sx={{ flex: 1, justifyContent: "center" }}>
        <InsertFooter
          mainInsertFile={mainInsertFile}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          totalMainPageCount={totalMainPageCount}
          setTotalMainPageCount={setTotalMainPageCount}
        />
      </Stack>

      <ShowResult responseData={responseData} />

      <Loader isLoading={isLoading} />

      <Stack sx={{ justifyContent: "flex-end", flexFlow: "wrap-reverse" }}>
        <Button
          variant="contained"
          sx={{
            width: 120,
            height: 80,
            fontSize: 22,
            fontWeight: 900,
            px: 10,
            bgcolor: "rgb(5, 80, 255)",
            color: "white",
            borderRadius: 6,
          }}
          onClick={handleUpload}
          disabled={!selectedFiles.length || isLoading || !mainInsertFile}
        >
          {activeButton.name}
        </Button>
      </Stack>
    </>
  );
};

export default Insert;
