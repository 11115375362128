import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { IChildren } from "../component/common";

interface INav {
  name: string;
  value: string;
  tooltip: string;
}

interface INavContext {
  activeButton: INav;
  setActiveButton: Dispatch<SetStateAction<INav>>;
  acceptFileType: { [key in string]: string[] };
  setAcceptFileType: Dispatch<SetStateAction<{ [key in string]: string[] }>>;
}

const NavContext = createContext<INavContext>({
  activeButton: { name: "Convert", value: "convert", tooltip: "Converts from PDF to Image and Image to PDF." },
  setActiveButton: () => {},
  acceptFileType: { "pdf/.pdf": [".pdf"] },
  setAcceptFileType: () => {},
});

export const useNav = () => {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error("useNav must be used within a NavProvider");
  }
  return context;
};

export const NavProvider: FC<IChildren> = ({ children }) => {
  const [activeButton, setActiveButton] = useState<INav>({
    name: "Convert",
    value: "convert",
    tooltip: "Converts from PDF to Image and Image to PDF.",
  });
  const [acceptFileType, setAcceptFileType] = useState<{ [key in string]: string[] }>({ "pdf/.pdf": [".pdf"] });

  return (
    <NavContext.Provider value={{ activeButton, setActiveButton, acceptFileType, setAcceptFileType }}>
      {children}
    </NavContext.Provider>
  );
};
