/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Stack, TextareaAutosize } from "@mui/material";

import { useNav, usePdf } from "../../providers";
import ExtractFooter from "./ExtractFooter";
import { CoreButton, InputFile, Loader, ShowResult } from "../common";

const Extract = () => {
  const { setActiveButton, setAcceptFileType } = useNav();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const [extractType, setExtractType] = useState<string>("text");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>("");

  useEffect(() => {
    setActiveButton({ name: "Extract", value: "extract", tooltip: "Extracts texts or images from PDF file/files" });
    setAcceptFileType({ "pdf/.pdf": [".pdf"] });
    setSelectedFiles([]);
    setResponseData(""); // Clear response data on initial load
  }, []);

  return (
    <>
      <InputFile isSingleFileAccept={true} isInputFileListVisible={true} />

      <Stack sx={{ flex: 1, justifyContent: "center" }}>
        <ExtractFooter extractType={extractType} setExtractType={setExtractType} />
      </Stack>

      <ShowResult responseData={responseData} />

      {/* Conditionally render TextareaAutosize only if extractType is "text" */}
      {extractType === "text" && (
        <>
          <TextareaAutosize
            maxRows={10}
            aria-label="extracted text"
            placeholder="Extracted text will appear here"
            value={responseData}
            style={{ width: "100%", padding: "8px" }}
          />
          <Button
            onClick={() => {
              const element = document.createElement("a");
              const file = new Blob([responseData], { type: "text/plain" });
              element.href = URL.createObjectURL(file);
              element.download = "extracted_text.txt";
              document.body.appendChild(element); // Required for this to work in Firefox
              element.click();
              document.body.removeChild(element); // Cleanup
            }}
          >
            Save Text to File
          </Button>
        </>
      )}

      <Loader isLoading={isLoading} />

      <CoreButton
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        payload={{ extractType }}
        setResponseData={setResponseData}
        disabled={!selectedFiles.length || isLoading}
      />
    </>
  );
};

export default Extract;
