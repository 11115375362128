/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, FC, useContext, useEffect, useState } from "react";
import { Alert, Stack } from "@mui/material";

import { IChildren, TAlertType } from "../component/common";

export interface IAlert {
  message: string;
  type: TAlertType;
}

interface IAlertContext {
  addAlert: (alertType: Omit<Omit<IAlert, "id">, "endTime">) => void;
}

export const AlertContext = createContext<IAlertContext>({
  addAlert: () => {},
});

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within a AlertProvider");
  }
  return context;
};

export const AlertProvider: FC<IChildren> = ({ children }) => {
  const [alertList, setAlertList] = useState<{ [key in string]: IAlert }>({});
  const [removedAlerts, setRemovedAlerts] = useState<string[]>([]);

  useEffect(() => removedAlerts.forEach((e) => removeAlert(e)), [removedAlerts]);
  useEffect(() => {
    return () => {};
  }, []);

  const addAlert = (val: Omit<Omit<IAlert, "id">, "endTime">) => {
    const id = Date.now() + 10000;
    setAlertList({ ...alertList, [id]: val });

    setTimeout(() => {
      setRemovedAlerts([...removedAlerts, id.toString()]);
    }, 10000);
  };

  const removeAlert = (uid: string) => {
    const keys = Object.keys(alertList).filter((e) => Number(e) <= Number(uid));
    const obj = { ...alertList };

    for (let i = 0; i < keys.length; i++) {
      delete obj[keys[i]];
    }
    setAlertList(obj);
  };

  return (
    <AlertContext.Provider value={{ addAlert }}>
      <Stack position="sticky" zIndex={9999999} top={44} sx={{ height: 0 }}>
        {Object.values(alertList).map(({ message, type }, inx) => {
          return (
            <Alert severity={type} key={inx} sx={{ width: "fit-content", m: "auto", mt: 1 }}>
              {message}
            </Alert>
          );
        })}
      </Stack>

      {children}
    </AlertContext.Provider>
  );
};
