/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { Download, ExpandMore } from "@mui/icons-material";
import { useNav } from "../../providers";
import CodeView from "../common/CodeView";

const Dashboard = () => {
  const { setActiveButton } = useNav();

  useEffect(() => {
    setActiveButton({ name: "Home", value: "home", tooltip: "tooltip" });
  }, []);

  return (
    <Stack spacing={3} sx={{ padding: 3 }}>
      {/* Windows Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel-windows-content" id="panel-windows-header">
          <Typography variant="h4">Windows Version</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">Instructions:</Typography>
          <Typography variant="body1" paragraph>
            1. Download the installer using the button below.
          </Typography>
          <Typography variant="body1" paragraph>
            2. You might see a warning regarding unrecognized app or something similar at the time of launching the installer. This is due to the lack of code-signing certificate, which can be very costly. Please note, this is an open-source project developed by individual contributers. Purchasing the expensive certificate is not an option for us. EnigmaPDF is safe and secure. Please proceed with the installation.
          </Typography>
          <Typography variant="body1" paragraph>
            3. To bypass the warning, click <strong>"More Info"</strong>, then select <strong>"Run Anyway"</strong>.
          </Typography>
          <Typography variant="body1" paragraph>
            4. Follow the on-screen instructions to complete the installation and launch EnigmaPDF.
          </Typography>
        </AccordionDetails>
        <AccordionActions>
          <Button
            variant="contained"
            endIcon={<Download />}
            onClick={() =>
              window.open("https://storage.googleapis.com/enigmapdf-storage/EnigmaPDF_Installer_setup_v2.5.exe", "_self")
            }
            sx={{ p: "8px 16px", fontSize: 16 }}
          >
            Download for Windows
          </Button>
        </AccordionActions>
      </Accordion>

      {/* Linux Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel-linux-content" id="panel-linux-header">
          <Typography variant="h4">Linux Version</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">Instructions:</Typography>
          <Typography variant="body1" paragraph>
            1. Download the AppImage file using the button below.
          </Typography>
          <Typography variant="body1" paragraph>
            2. Open a terminal and navigate to the directory containing the downloaded file.
          </Typography>
          <Typography variant="body1" paragraph>
            3. Make the file executable by running the command:
          </Typography>
          <CodeView>chmod +x EnigmaPDF-x86_64.AppImage</CodeView>
          <Typography variant="body1" paragraph>
            4. Launch the application with:
          </Typography>
          <CodeView>./EnigmaPDF-x86_64.AppImage</CodeView>
          <Typography variant="body1" paragraph>
            5. EnigmaPDF is tested on Ubuntu and should work on most Linux distributions. If you encounter any issues, let us know.
          </Typography>
        </AccordionDetails>
        <AccordionActions>
          <Button
            variant="contained"
            endIcon={<Download />}
            onClick={() =>
              window.open("https://storage.googleapis.com/enigmapdf-storage/EnigmaPDF-x86_64.AppImage", "_self")
            }
            sx={{ p: "8px 16px", fontSize: 16 }}
          >
            Download for Linux
          </Button>
        </AccordionActions>
      </Accordion>

      {/* Macintosh Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel-mac-content" id="panel-mac-header">
          <Typography variant="h4">Macintosh Version</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">Instructions:</Typography>
          <Typography variant="body1" paragraph>
            1. Download the DMG file using the button below.
          </Typography>
          <Typography variant="body1" paragraph>
            2. Open the downloaded file and drag EnigmaPDF into your Applications folder.
          </Typography>
          <Typography variant="body1" paragraph>
            3. To bypass the security warning, choose one of the following methods:
          </Typography>
          <Typography variant="h6">Method 1: Through Finder</Typography>
          <Typography variant="body1" paragraph>
            - Open Finder and navigate to the **Applications** folder.
          </Typography>
          <Typography variant="body1" paragraph>
            - Right-click (or Control-click) on EnigmaPDF and select <strong>"Open"</strong>.
          </Typography>
          <Typography variant="body1" paragraph>
            - Confirm the warning dialog by clicking <strong>"Open"</strong>. This action will mark EnigmaPDF as safe for future use.
          </Typography>
          <Typography variant="h6">Method 2: Through System Preferences</Typography>
          <Typography variant="body1" paragraph>
            - Open System Preferences &gt; Security & Privacy.
          </Typography>
          <Typography variant="body1" paragraph>
            - Under the General tab, click <strong>"Open Anyway"</strong> for the EnigmaPDF app.
          </Typography>
          <Typography variant="body1" paragraph>
            - Confirm the warning dialog by clicking <strong>"Open"</strong>.
          </Typography>
        </AccordionDetails>
        <AccordionActions>
          <Button
            variant="contained"
            endIcon={<Download />}
            onClick={() =>
              window.open("https://storage.googleapis.com/enigmapdf-storage/EnigmaPDF.dmg", "_self")
            }
            sx={{ p: "8px 16px", fontSize: 16 }}
          >
            Download for Mac
          </Button>
        </AccordionActions>
      </Accordion>
    </Stack>
  );
};

export default Dashboard;
