import { Box, Typography, keyframes } from "@mui/material";
import { useNavigate } from "react-router-dom";

const spin = keyframes`
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const MarqueeText = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ maxWidth: "100vw", overflow: "hidden" }}>
      <Box
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          boxSizing: "border-box",
          animation: `${spin} 20s linear infinite`,
          "&:hover": {
            animationPlayState: "paused", // Pauses the animation on hover
          },
          bgcolor: "lightgrey",
          mb: 8,
          position: "relative",
          height: 24,
        }}
      >
        <Typography
          sx={{
            display: "inline-block",
            paddingLeft: 1,
            cursor: "pointer",
            fontWeight: 700,
            textShadow: `2px 2px 2px white`,
            color: "teal",
            position: "absolute",
          }}
          onClick={() => navigate("/")}
        >
          download more secure and fast PC versions, click here for Windows, click here for Linux, click here for
          Macintosh
        </Typography>
      </Box>
    </Box>
  );
};

export default MarqueeText;
