import { FC } from "react";
import { Box, FormControl, Stack, SxProps, TextField } from "@mui/material";

interface IInputTextProps {
  label: string;
  inputValue: string;
  handleInputChange: (val: string) => void;
  customStyle?: SxProps;
  fieldStyle?: SxProps;
  labelWidth?: string;
}

const InputText: FC<IInputTextProps> = ({
  label,
  inputValue,
  handleInputChange,
  customStyle = {},
  fieldStyle = {},
  labelWidth = "auto",
}) => {
  return (
    <Stack direction="row" sx={{ ...customStyle }}>
      <Box sx={{ my: "auto", pr: 4, width: labelWidth }}>{label}</Box>
      <FormControl sx={{ minWidth: 200, ...fieldStyle }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
        />
      </FormControl>
    </Stack>
  );
};

export default InputText;
