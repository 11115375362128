import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { IChildren } from "../component/common";

interface IFile extends File {
  preview: string;
}

interface IPdfContext {
  selectedFiles: IFile[];
  setSelectedFiles: Dispatch<SetStateAction<IFile[]>>;
  fileList: string[];
  setFileList: Dispatch<SetStateAction<string[]>>;
  isSingleFileAccept: boolean;
  setIsSingleFileAccept: Dispatch<SetStateAction<boolean>>;

  totalPageCount: number;
  setTotalPageCount: Dispatch<SetStateAction<number>>;
  splitPage: number;
  setSplitPage: Dispatch<SetStateAction<number>>;
}

const PdfContext = createContext<IPdfContext>({
  selectedFiles: [],
  setSelectedFiles: () => {},
  fileList: [],
  setFileList: () => {},
  isSingleFileAccept: false,
  setIsSingleFileAccept: () => {},

  totalPageCount: 0,
  setTotalPageCount: () => {},
  splitPage: 0,
  setSplitPage: () => {},
});

export const usePdf = () => {
  const context = useContext(PdfContext);
  if (context === undefined) {
    throw new Error("usePdf must be used within a PdfProvider");
  }
  return context;
};

export const PdfProvider: FC<IChildren> = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
  const [fileList, setFileList] = useState<string[]>([]);
  const [isSingleFileAccept, setIsSingleFileAccept] = useState<boolean>(false);

  // SPLIT
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const [splitPage, setSplitPage] = useState<number>(0);

  return (
    <PdfContext.Provider
      value={{
        selectedFiles,
        setSelectedFiles,
        fileList,
        setFileList,
        isSingleFileAccept,
        setIsSingleFileAccept,

        totalPageCount,
        setTotalPageCount,
        splitPage,
        setSplitPage,
      }}
    >
      {children}
    </PdfContext.Provider>
  );
};
