/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import { useNav, usePdf } from "../../providers";
import SplitFooter from "./SplitFooter";
import { CoreButton, InputFile, Loader, ShowResult } from "../common";

const Split = () => {
  const { setActiveButton, setAcceptFileType } = useNav();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any[]>([]);
  const [splitPageIndex, setSplitPageIndex] = useState<number>(0);

  useEffect(() => {
    setActiveButton({ name: "Split", value: "split", tooltip: "Splits a .pdf file into two parts." });
    setAcceptFileType({ "pdf/.pdf": [".pdf"] });
    setSelectedFiles([]);
    setResponseData([]);
  }, []);

  return (
    <>
      <InputFile isSingleFileAccept={true} isInputFileListVisible={true} />

      <Stack sx={{ flex: 1, justifyContent: "center" }}>
        <SplitFooter splitPageIndex={splitPageIndex} setSplitPageIndex={setSplitPageIndex} />
      </Stack>

      <ShowResult responseData={responseData} />

      <Loader isLoading={isLoading} />

      <CoreButton
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        payload={{ splitPageIndex }}
        setResponseData={setResponseData}
        disabled={!selectedFiles.length || isLoading}
      />
    </>
  );
};

export default Split;
