import { FC } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

interface ILoaderProps {
  isLoading: boolean;
}

const Loader: FC<ILoaderProps> = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

export default Loader;
