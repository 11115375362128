/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { PDFDocumentProxy } from "pdfjs-dist";

import { usePdf } from "../../providers";
import { InputSelect } from "../common";

interface ISplitFooterProps {
  splitPageIndex: number;
  setSplitPageIndex: Dispatch<SetStateAction<number>>;
}

const SplitFooter: FC<ISplitFooterProps> = ({ splitPageIndex, setSplitPageIndex }) => {
  const { totalPageCount, setTotalPageCount, selectedFiles } = usePdf();

  useEffect(() => {
    if (selectedFiles.length) {
      setSplitPageIndex(1);

      (async () => {
        const loadingTask = pdfjsLib.getDocument(selectedFiles[0].preview);
        const pdf: PDFDocumentProxy = await loadingTask.promise;

        setTotalPageCount(pdf.numPages);
      })();
    }
  }, [selectedFiles]);

  return (
    <InputSelect
      label="Split Page"
      customStyle={{ alignSelf: "center" }}
      selectOptions={Array.from(Array(totalPageCount).keys()).map((e) => ({
        name: e.toString(),
        value: e.toString(),
      }))}
      handleOptionChange={(val) => setSplitPageIndex(Number(val))}
      preSelectedValue={splitPageIndex.toString()}
    />
  );
};

export default SplitFooter;
