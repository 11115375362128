import { FC } from "react";
import { Box, FormControl, Stack, SxProps, TextField } from "@mui/material";

interface IInputNumberProps {
  label: string;
  inputValue: number;
  handleInputChange: (val: number) => void;
  maxValue?: number;
  minValue?: number;
  customStyle?: SxProps;
  fieldStyle?: SxProps;
}

const InputNumber: FC<IInputNumberProps> = ({
  label,
  inputValue,
  handleInputChange,
  maxValue,
  minValue,
  customStyle = {},
  fieldStyle = {},
}) => {
  return (
    <Stack direction={"row"} sx={{ ...customStyle }}>
      <Box sx={{ my: "auto", pr: 4 }}>{label}</Box>
      <FormControl sx={{ minWidth: 200, ...fieldStyle }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={inputValue}
          onChange={(e) => {
            let num = Number(e.target.value);
            if (typeof maxValue === "number" && num > maxValue) {
              num = maxValue;
            }
            if (typeof minValue === "number" && num < minValue) {
              num = minValue;
            }
            handleInputChange(num);
          }}
          type="number"
        />
      </FormControl>
    </Stack>
  );
};

export default InputNumber;
