import { Stack } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";

import { InputNumber, InputSelect } from "../common";
import { usePdf } from "../../providers";

interface IPageRangeOptionsProps {
  totalSecondaryPageCount: number;
  selectedInsertOption: string;
  setSelectedInsertOption: Dispatch<SetStateAction<string>>;
  startPage: number;
  setStartPage: Dispatch<SetStateAction<number>>;
  endPage: number;
  setEndPage: Dispatch<SetStateAction<number>>;
}

const PageRangeOptions: FC<IPageRangeOptionsProps> = ({
  totalSecondaryPageCount,
  selectedInsertOption,
  setSelectedInsertOption,
  startPage,
  setStartPage,
  endPage,
  setEndPage,
}) => {
  const { selectedFiles } = usePdf();

  const handleStartPageChange = (val: number) => {
    if (val > endPage) {
      setEndPage(val);
    }
    setStartPage(val);
  };

  return (
    <Stack sx={{ pr: 8 }} gap={2}>
      <InputSelect
        label="Insert Options"
        selectOptions={[
          { name: "Page Range", value: "Page Range" },
          { name: "Whole Document", value: "Whole Document" },
          { name: "Single Page", value: "Single Page" },
        ]}
        preSelectedValue={selectedInsertOption}
        handleOptionChange={setSelectedInsertOption}
        customStyle={{ pb: 8 }}
      />

      {selectedFiles.length && selectedInsertOption === "Single Page" ? (
        <InputNumber
          label="Select Page"
          inputValue={startPage}
          handleInputChange={(val) => setStartPage(val)}
          maxValue={totalSecondaryPageCount}
          minValue={1}
          customStyle={{ alignSelf: "center" }}
        />
      ) : (
        <></>
      )}

      {selectedFiles.length && selectedInsertOption === "Page Range" ? (
        <>
          <InputNumber
            label="Start Page"
            inputValue={startPage}
            handleInputChange={handleStartPageChange}
            maxValue={totalSecondaryPageCount}
            minValue={1}
            customStyle={{ alignSelf: "center" }}
          />

          <InputNumber
            label="End Page"
            inputValue={endPage}
            handleInputChange={(val) => setEndPage(val)}
            maxValue={totalSecondaryPageCount}
            minValue={1}
            customStyle={{ alignSelf: "center" }}
          />
        </>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default PageRangeOptions;
