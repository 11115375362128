import { Box, Button, Stack, Tooltip } from "@mui/material";
import React, { FC } from "react";
import axios from "axios";

import { useAlert, useNav, usePdf } from "../../providers";
import { publicApi } from "../../services";

interface ICoreButtonProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  payload: any;
  setResponseData: React.Dispatch<React.SetStateAction<any[]>>;
  disabled: boolean;
}

const CoreButton: FC<ICoreButtonProps> = ({ isLoading, setIsLoading, payload, setResponseData, disabled }) => {
  const { activeButton } = useNav();
  const { addAlert } = useAlert();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const handleUpload = async () => {
    setIsLoading(true);
    const urls = await Promise.all(
      selectedFiles.map(async (uploadedFile) => {
        const formData = new FormData();

        formData.append("file", uploadedFile);
        try {
          let res = await axios.post(`${process.env.REACT_APP_BUCKET_URL}/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          return res.data;
        } catch (error) {
          console.log(error);
          return;
        }
      })
    );

    try {
      const { code, data, message } = await publicApi({
        url: `${process.env.REACT_APP_FLASK_URL}/${activeButton.value}`,
        payload: { ...payload, files: urls },
        method: "POST",
      });

      if (code < 300) {
        message && addAlert({ message, type: "success" });
      } else {
        message && addAlert({ message, type: "error" });
      }

      setResponseData(data);
      setSelectedFiles([]);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  return (
    <Stack sx={{ justifyContent: "flex-end", flexFlow: "wrap-reverse" }}>
      <Tooltip title={activeButton.tooltip}>
        <Box>
          <Button
            variant="contained"
            sx={{
              width: "auto",
              height: 80,
              fontSize: 22,
              fontWeight: 900,
              px: 5,
              bgcolor: "rgb(5, 80, 255)",
              color: "white",
              borderRadius: 6,
            }}
            onClick={handleUpload}
            disabled={disabled}
          >
            {activeButton.name}
          </Button>
        </Box>
      </Tooltip>
    </Stack>
  );
};

export default CoreButton;
