import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Message from "./Message";

const Footer = () => {
  const theme = useTheme();
  const [openMessage, setOpenMessage] = useState<boolean>(false);

  return (
    <Box textAlign="center">
      <Button variant="contained" sx={{ px: 6, borderRadius: 10, mb: 2 }} onClick={() => setOpenMessage(true)}>
        Leave A Message
      </Button>
      <Box sx={{ bgcolor: theme.palette.grey[200], p: 3, textAlign: "center" }}>
        <Typography component={"span"}>Copyright © EnigmaPDF 2024. All rights reserved.</Typography>
        <Button>License</Button>
        <Typography>Developed by Arunava Kar & Biswajit Barman</Typography>
      </Box>
      <Message openMessage={openMessage} setOpenMessage={setOpenMessage} />
    </Box>
  );
};

export default Footer;
