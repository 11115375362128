import { FC } from "react";

import { InputPassword } from "../common";

interface IPasswordFooterProps {
  passwordText: string;
  setPasswordText: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordFooter: FC<IPasswordFooterProps> = ({ passwordText, setPasswordText }) => {
  return (
    <>
      <InputPassword
        inputValue={passwordText}
        label="Type your Password"
        handleInputChange={setPasswordText}
        customStyle={{ mt: 2, justifyContent: "center" }}
      />
    </>
  );
};

export default PasswordFooter;
