import { Container, Stack, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";

import { Footer, NavBar } from "./component/common";
import { MainRoutes } from "./routes";

const App = () => {
  const navigate = useNavigate();

  return (
    <NavBar>
      <Stack sx={{ height: `calc(100vh - 40px)` }}>
        <Marquee>
          <Typography
            sx={{
              display: "inline-block",
              paddingLeft: 1,
              cursor: "pointer",
              fontWeight: 700,
              textShadow: `2px 2px 2px white`,
              color: "teal",
            }}
            onClick={() => navigate("/install")}
          >
            Install open-source and more secured PC versions of EnigmaPDF. Click here to download it for Windows, Linux and
            Macintosh
          </Typography>
        </Marquee>
        <Container sx={{ flex: 1, p: 3, display: "flex", flexDirection: "column" }}>
          <MainRoutes />
        </Container>
        <Footer />
      </Stack>
    </NavBar>
  );
};

export default App;
