/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, MouseEvent as ReactMouseEvent, useEffect, useState } from "react";
import { Box, Checkbox, Paper, Skeleton, Stack, TablePagination, Typography } from "@mui/material";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";

import { usePdf } from "../../providers";

interface IRemoveFooterProps {
  selectedPages: { [key in number]: boolean };
  setSelectedPages: React.Dispatch<React.SetStateAction<{ [key in number]: boolean }>>;
}

const RemoveFooter: FC<IRemoveFooterProps> = ({ selectedPages, setSelectedPages }) => {
  const { selectedFiles } = usePdf();

  const [startBox, setStartBox] = useState<number | null>(null);
  const [endBox, setEndBox] = useState<number | null>(null);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [tempSelectedPages, setTempSelectedPages] = useState<{ [key in number]: boolean }>({});

  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangePage = (event: ReactMouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  useEffect(() => {
    if (selectedFiles.length) {
      setIsLoading(true);
      const renderThumbnails = async () => {
        try {
          const loadingTask = pdfjsLib.getDocument(selectedFiles[0].preview);
          const pdf: PDFDocumentProxy = await loadingTask.promise;

          setTotalPageCount(pdf.numPages);

          const thumbPages: string[] = [];
          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page: PDFPageProxy = await pdf.getPage(pageNumber);
            const scale = 0.2; // Thumbnail size scaling
            const viewport = page.getViewport({ scale });

            // Prepare canvas
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            if (!context) continue; // Check context validity

            canvas.width = viewport.width;
            canvas.height = viewport.height;

            // Render page into the canvas context
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            await page.render(renderContext).promise;

            // Convert canvas to base64 image
            const thumbnail = canvas.toDataURL();
            thumbPages.push(thumbnail);
          }

          setThumbnails(thumbPages);
          setIsLoading(false);
        } catch (error) {
          console.error("Error rendering thumbnails:", error);
        }
      };

      renderThumbnails();
    }
  }, [selectedFiles]);

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [startBox, endBox]);

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [startBox, endBox]);

  const handleMouseDown = async (e: MouseEvent) => {
    setIsMouseDown(true);
  };

  const handleMouseUp = async (e: MouseEvent) => {
    setIsMouseDown(false);
    let pages: { [key in number]: boolean } = {};

    if (typeof startBox === "number" && typeof endBox === "number") {
      if (startBox < endBox) {
        for (let i = startBox; i <= endBox; i++) {
          pages[i] = true;
        }
      } else {
        for (let i = endBox; i <= startBox; i++) {
          pages[i] = true;
        }
      }
      setSelectedPages({ ...selectedPages, ...pages });
      setTempSelectedPages({});
    }
    setStartBox(null);
    setEndBox(null);
  };

  const handleMouseEnter = (pageNo: number) => {
    if (isMouseDown) {
      if (typeof startBox !== "number") {
        setStartBox(pageNo);
        setTempSelectedPages({ [pageNo]: true });
      }
      if (typeof startBox === "number") {
        setEndBox(pageNo);

        let pages: { [key in number]: boolean } = {};
        if (typeof startBox === "number") {
          if (startBox < pageNo) {
            for (let i = startBox; i <= pageNo; i++) {
              pages[i] = true;
            }
          } else {
            for (let i = pageNo; i <= startBox; i++) {
              pages[i] = true;
            }
          }
        }
        setTempSelectedPages(pages);
      }
    }
  };

  const handleMouseLeave = (index: number) => {
    if (isMouseDown) {
      if (startBox) {
        setEndBox(index);
      }
    }
  };

  if (!selectedFiles.length) {
    return <></>;
  }

  return (
    <Box>
      <Stack direction="row" flexWrap="wrap" gap={4}>
        {Array.from(Array(rowsPerPage)).map((_e, index) => {
          const currentIndex = currentPage * rowsPerPage + index;

          if (!thumbnails[currentIndex]) {
            return <></>;
          }

          if (isLoading) {
            return (
              <Box sx={{ pt: 0.5, width: 122, height: 178 }} key={index}>
                <Skeleton variant="rectangular" width={122} height={158} />
                <Skeleton width="60%" />
              </Box>
            );
          }
          return (
            <Paper
              key={index}
              sx={{
                textAlign: "center",
                userSelect: "none",
                position: "relative",
                cursor: "pointer",
              }}
              elevation={Boolean(selectedPages[currentIndex]) || Boolean(tempSelectedPages[currentIndex]) ? 4 : 0}
              onMouseEnter={() => handleMouseEnter(currentIndex)}
              onMouseLeave={() => handleMouseLeave(currentIndex)}
              onClick={() =>
                setSelectedPages({
                  ...selectedPages,
                  [currentIndex]: !selectedPages[currentIndex],
                })
              }
            >
              <Checkbox
                checked={Boolean(selectedPages[currentIndex]) || Boolean(tempSelectedPages[currentIndex])}
                sx={{ position: "absolute", top: 10, left: 0, color: "red" }}
              />
              <Box
                sx={{
                  backgroundImage: `url(${thumbnails[currentIndex]})`,
                  width: 122,
                  height: 158,
                  backgroundSize: "cover",
                }}
              />
              <Typography>page- {currentIndex + 1}</Typography>
            </Paper>
          );
        })}
      </Stack>

      <TablePagination
        component="div"
        count={totalPageCount}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .MuiIconButton-root, .MuiButtonBase-root": {
            color: "#2f95ef!important",
          },
        }}
      />
    </Box>
  );
};

export default RemoveFooter;
