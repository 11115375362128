import { FC } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { CopyAll } from "@mui/icons-material";

interface ICodeViewProps {
  children: string;
}

const CodeView: FC<ICodeViewProps> = ({ children }) => {
  return (
    <Box sx={{ p: "8px", bgcolor: "#e8e7e7" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{}} variant="body2">
          bash
        </Typography>
        <Button variant="text" onClick={() => navigator.clipboard.writeText(children)} startIcon={<CopyAll />}>
          copy code
        </Button>
      </Stack>
      <Box sx={{ p: 3 }}>
        <code style={{ whiteSpace: "pre-wrap" }}>{children}</code>
      </Box>
    </Box>
  );
};

export default CodeView;
