import { FC, FormEvent, useState } from "react";
import { useAlert} from "../../providers";


import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import axios from "axios";


interface IMessageProps {
  openMessage: boolean;
  setOpenMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Message: FC<IMessageProps> = ({ openMessage, setOpenMessage }) => {
  const { addAlert } = useAlert();
  const [error, setError] = useState<string | null>(null);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const email = formJson.email as string;
    const message = formJson.message as string;

    // Validate email
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Clear any previous error
    setError(null);

    try {
      // Send data to the backend
      const response = await axios.post(`${process.env.REACT_APP_BUCKET_URL}/save_message`, {
        email_id: email,
        message,
      });

      // Show the message from backend in alert
      const backendMessage = response.data.message 
      if (response.status < 300) {
        addAlert({ message: backendMessage, type: "success" });
        setOpenMessage(false); // Close the dialog on success
      } else {
        addAlert({ message: backendMessage, type: "error" });
      }
    }
     catch (error) {
      console.error("Error sending message:", error);
      alert("There was an error sending your message. Please try again later.");
    }
  };

  return (
    <Dialog
      open={openMessage}
      onClose={() => setOpenMessage(false)}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>Leave A Message</DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you face any issue or have any suggestion to improve our product please feel free to contact us.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          error={!!error}
          helperText={error}
        />
        <TextField
          required
          margin="dense"
          id="message"
          name="message"
          label="Message"
          type="text"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenMessage(false)}>Cancel</Button>
        <Button type="submit" variant="contained">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Message;
