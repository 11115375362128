/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from "react";
import { Stack } from "@mui/material";

import { positionList } from "../common/constant";
import { InputSelect, InputText } from "../common";

interface IWatermarkFooterProps {
  watermarkPosition: string;
  setWatermarkPosition: (val: string) => void;
  watermarkText: string;
  setWatermarkText: (val: string) => void;
}

const WatermarkFooter: FC<IWatermarkFooterProps> = ({
  watermarkPosition,
  setWatermarkPosition,
  watermarkText,
  setWatermarkText,
}) => {
  return (
    <Stack direction={{ sm: "column", md: "row" }}>
      <InputText
        inputValue={watermarkText}
        label="Watermark Text"
        handleInputChange={setWatermarkText}
        customStyle={{ mt: 2, justifyContent: { sm: "center", md: "left" }, mr: { sm: 8, xs: 0 } }}
        fieldStyle={{ width: { sm: "100%", md: "auto" } }}
      />
      <InputSelect
        label="Position"
        selectOptions={positionList}
        preSelectedValue={watermarkPosition}
        handleOptionChange={setWatermarkPosition}
        customStyle={{ mt: 2, justifyContent: { sm: "center", md: "left" } }}
        labelWidth="8em"
      />
    </Stack>
  );
};

export default WatermarkFooter;
