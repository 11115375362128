import { FC, MouseEvent, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, FormControl, IconButton, InputAdornment, OutlinedInput, Stack, SxProps } from "@mui/material";

interface IInputPasswordProps {
  label: string;
  inputValue: string;
  handleInputChange: (val: string) => void;
  customStyle?: SxProps;
}

const InputPassword: FC<IInputPasswordProps> = ({ label, inputValue, handleInputChange, customStyle = {} }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Stack direction={"row"} sx={{ ...customStyle }}>
      <Box sx={{ my: "auto", pr: 4 }}>{label}</Box>

      <FormControl sx={{ minWidth: 200 }}>
        <OutlinedInput
          id="outlined-adornment-password"
          value={inputValue}
          type={showPassword ? "text" : "password"}
          onChange={(e) => handleInputChange(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );
};

export default InputPassword;
