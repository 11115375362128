/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChangeEvent,
  Dispatch,
  FC,
  Fragment,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Box, Paper, Skeleton, Stack, TablePagination, Typography } from "@mui/material";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";

import { IFile, InputNumber } from "../common";

interface IInsertFooterProps {
  mainInsertFile: IFile | null;
  selectedPage: number;
  setSelectedPage: Dispatch<SetStateAction<number>>;
  totalMainPageCount: number;
  setTotalMainPageCount: Dispatch<SetStateAction<number>>;
}

const InsertFooter: FC<IInsertFooterProps> = ({
  mainInsertFile,
  selectedPage,
  setSelectedPage,
  totalMainPageCount,
  setTotalMainPageCount,
}) => {
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangePage = (event: ReactMouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  useEffect(() => {
    if (mainInsertFile) {
      setIsLoading(true);
      const renderThumbnails = async () => {
        try {
          const loadingTask = pdfjsLib.getDocument(mainInsertFile.preview);
          const pdf: PDFDocumentProxy = await loadingTask.promise;

          setTotalMainPageCount(pdf.numPages);

          const thumbPages: string[] = [];
          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page: PDFPageProxy = await pdf.getPage(pageNumber);
            const scale = 0.2; // Thumbnail size scaling
            const viewport = page.getViewport({ scale });

            // Prepare canvas
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            if (!context) continue; // Check context validity

            canvas.width = viewport.width;
            canvas.height = viewport.height;

            // Render page into the canvas context
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            await page.render(renderContext).promise;

            // Convert canvas to base64 image
            const thumbnail = canvas.toDataURL();
            thumbPages.push(thumbnail);
          }

          setThumbnails(thumbPages);
          setIsLoading(false);
        } catch (error) {
          console.error("Error rendering thumbnails:", error);
        }
      };

      renderThumbnails();
    }
  }, [mainInsertFile]);

  const handleInsertPageChange = (val: number) => {
    setSelectedPage(val);
    if (val < (currentPage + 1) * rowsPerPage || val > (currentPage + 1) * rowsPerPage) {
      setCurrentPage(Math.floor(val / rowsPerPage));
    }
  };

  if (!mainInsertFile) {
    return <></>;
  }

  return (
    <Box>
      <InputNumber
        label="Insert Page At"
        inputValue={selectedPage}
        handleInputChange={handleInsertPageChange}
        maxValue={totalMainPageCount}
        minValue={0}
        customStyle={{ alignSelf: "center", my: 6 }}
      />

      <Stack direction="row" flexWrap="wrap" rowGap={4}>
        {Array.from(Array(rowsPerPage)).map((_e, index) => {
          const currentIndex = currentPage * rowsPerPage + index;

          if (!thumbnails[currentIndex]) {
            return <Fragment key={index} />;
          }

          if (isLoading) {
            return (
              <Box sx={{ pt: 0.5, width: 122, height: 178 }} key={index}>
                <Skeleton variant="rectangular" width={122} height={158} />
                <Skeleton width="60%" />
              </Box>
            );
          }

          return (
            <Stack key={index} direction="row">
              <Box
                sx={{ width: 20, height: 178, cursor: "pointer", "&:hover": { bgcolor: "lightgray" } }}
                onClick={() => setSelectedPage(currentIndex)}
              >
                <Box
                  sx={{
                    width: "1px",
                    height: 1,
                    border: `1px solid ${selectedPage === currentIndex ? "red" : "transparent"}`,
                    m: "auto",
                  }}
                />
              </Box>

              <Paper sx={{ textAlign: "center", userSelect: "none", position: "relative" }}>
                <Box
                  sx={{
                    backgroundImage: `url(${thumbnails[currentIndex]})`,
                    width: 122,
                    height: 158,
                    backgroundSize: "cover",
                  }}
                />
                <Typography>page- {currentIndex + 1}</Typography>
              </Paper>

              {thumbnails.length - 1 === currentIndex && (
                <Box
                  sx={{ width: 20, height: 178, cursor: "pointer", "&:hover": { bgcolor: "lightgray" } }}
                  onClick={() => setSelectedPage(currentIndex + 1)}
                >
                  <Box
                    sx={{
                      width: "1px",
                      height: 1,
                      border: `1px solid ${selectedPage === currentIndex + 1 ? "red" : "transparent"}`,
                      m: "auto",
                    }}
                  />
                </Box>
              )}

              {index === (currentPage + 1) * rowsPerPage - 1 && (
                <Box
                  sx={{ width: 20, height: 178, cursor: "pointer", "&:hover": { bgcolor: "lightgray" } }}
                  onClick={() => setSelectedPage(currentIndex + 1)}
                >
                  <Box
                    sx={{
                      width: "1px",
                      height: 1,
                      border: `1px solid ${selectedPage === currentIndex + 1 ? "red" : "transparent"}`,
                      m: "auto",
                    }}
                  />
                </Box>
              )}
            </Stack>
          );
        })}
      </Stack>

      <TablePagination
        component="div"
        count={totalMainPageCount}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .MuiIconButton-root, .MuiButtonBase-root": {
            color: "#2f95ef!important",
          },
          "& .Mui-disabled": {
            color: "lightgray!important",
          },
        }}
      />
    </Box>
  );
};

export default InsertFooter;
