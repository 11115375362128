/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import { useNav, usePdf } from "../../providers";
import { CoreButton, InputFile, Loader, ShowResult } from "../common";
import ResizeDpi from "./ResizeDpi";

const Resize = () => {
  const { setActiveButton, setAcceptFileType } = useNav();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const [selectedDpi, setSelectedDpi] = useState<string>("15");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any[]>([]);

  useEffect(() => {
    setActiveButton({
      name: "Resize",
      value: "resize",
      tooltip:
        "This feature is still experimental. It works best on image based PDFs. Select a low DPI like 25 to reduce the size of a PDF file.",
    });
    setAcceptFileType({ "pdf/.pdf": [".pdf"] });
    setSelectedFiles([]);
    setResponseData([]);
  }, []);

  return (
    <>
      <ResizeDpi selectedDpi={selectedDpi} setSelectedDpi={setSelectedDpi} />
      <InputFile isSingleFileAccept={false} isInputFileListVisible={true} />

      <Stack sx={{ flex: 1, justifyContent: "center" }}></Stack>

      <ShowResult responseData={responseData} />

      <Loader isLoading={isLoading} />

      <CoreButton
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        payload={{ selectedDpi }}
        setResponseData={setResponseData}
        disabled={!selectedFiles.length || isLoading}
      />
    </>
  );
};

export default Resize;
