import Convert from "../component/convert/Convert";
import Dashboard from "../component/dashboard/Dashboard";
import Extract from "../component/extract/Extract";
import Insert from "../component/insert/Insert";
import Merge from "../component/merge/Merge";
import Password from "../component/password/Password";
import Remove from "../component/remove/Remove";
import Resize from "../component/resize/Resize";
import Split from "../component/split/Split";
import Watermark from "../component/watermark/Watermark";
import { NotFound } from "../pages";

export const publicRoutes = [
  { path: "/", element: <Convert />, name: "Convert" },
  { path: "/extract", element: <Extract />, name: "Extract" },
  { path: "/split", element: <Split />, name: "Split" },
  { path: "/insert", element: <Insert />, name: "Insert" },
  { path: "/merge", element: <Merge />, name: "Merge" },
  { path: "/password", element: <Password />, name: "Password" },
  { path: "/watermark", element: <Watermark />, name: "Watermark" },
  { path: "/remove", element: <Remove />, name: "Remove" },
  { path: "/resize", element: <Resize />, name: "Resize" },
  { path: "/install", element: <Dashboard />, name: "Install" },
  { path: "*", element: <NotFound /> },
];
