import { FC } from "react";
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Stack, SxProps } from "@mui/material";

export interface IOptions {
  value: string;
  name: string;
}

interface IInputSelectProps {
  label: string;
  selectOptions: IOptions[];
  preSelectedValue: string;
  handleOptionChange: (val: string) => void;
  customStyle?: SxProps;
  fieldStyle?: SxProps;
  disabled?: boolean;
  labelWidth?: string;
}

const InputSelect: FC<IInputSelectProps> = ({
  label,
  preSelectedValue,
  selectOptions,
  handleOptionChange,
  customStyle = {},
  fieldStyle = {},
  disabled = false,
  labelWidth = "auto",
}) => {
  return (
    <Stack direction={"row"} sx={{ ...customStyle }}>
      <Box sx={{ my: "auto", pr: 4, width: { md: "auto", xs: labelWidth } }}>{label}</Box>
      <FormControl sx={{ minWidth: 200, ...fieldStyle }} disabled={disabled}>
        <Select
          labelId="select-convert-form"
          id="demo-simple-select"
          value={preSelectedValue}
          onChange={(event: SelectChangeEvent) => handleOptionChange(event.target.value)}
        >
          {selectOptions.map(({ name, value }) => {
            return (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default InputSelect;
