import { createContext, FC, useMemo, useState } from "react";
import { Components, ThemeProvider, colors, createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

import { IChildren } from "../component/common";

const spacing = 4;

const components: Components = {
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: "40px !important",
        "& .MuiButtonBase-root": {
          color: colors.common.white,
        },
      },
    },
  },

  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "capitalize",
        minWidth: 0,
        fontSize: 12,
        padding: "3px 12px",
      },
    },
  },

  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        padding: 0,
        minWidth: 60,
        maxHeight: 60,
        "& .MuiBottomNavigationAction-label": {
          fontSize: 10,
        },
        "& .Mui-selected": {
          fontSize: "11px !important",
        },
      },
    },
  },

  MuiListItemButton: {
    styleOverrides: {
      root: {
        paddingTop: "6px",
        paddingBottom: "6px",
      },
    },
  },

  MuiListItemText: {
    styleOverrides: {
      root: {
        margin: 0,
        "& .MuiListItemText-primary": { fontSize: 12 },
      },
    },
  },
};

const palette = {
  primary: {
    light: "#F5FAFE",
    main: `rgb(47, 149, 239)`,
    dark: "#2F95EF",
    contrastText: "#fff",
  },
  secondary: {
    light: "#ff7961",
    main: "#f44336",
    dark: "#ba000d",
    contrastText: "#000",
  },

};

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

type TMode = "light" | "dark";

const Theme: FC<IChildren> = ({ children }) => {
  const [mode, setMode] = useState<TMode>("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => setMode((prevMode) => (prevMode === "light" ? "dark" : "light")),
    }),
    []
  );

  let theme = useMemo(
    () =>
      createTheme({
        palette: {
          ...palette,
          mode,
          ...(mode === "light" ? {} : { background: { default: grey[900] } }),
        },
        spacing,
      }),
    [mode]
  );

  theme = createTheme(theme, {
    components: {
      ...components,
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            whiteSpace: "nowrap",
            fontSize: "12px",
            padding: "3px",
          },
        },
        variants: [
          {
            props: { variant: "text" },
            style: {
              minWidth: "auto",
              color: theme.palette.text.secondary,
              "& .MuiButton-startIcon": { margin: 0 },
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        ],
      },
    },
  });

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Theme;
