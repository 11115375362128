/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import { useNav, usePdf } from "../../providers";
import RemoveFooter from "./RemoveFooter";
import { CoreButton, InputFile, Loader, ShowResult } from "../common";

const Remove = () => {
  const { setActiveButton, setAcceptFileType } = useNav();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const [selectedPages, setSelectedPages] = useState<{ [key in number]: boolean }>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any[]>([]);

  useEffect(() => {
    setActiveButton({ name: "Remove", value: "remove", tooltip: "Deletes a page or multiple pages from a PDF." });
    setAcceptFileType({ "pdf/.pdf": [".pdf"] });
    setSelectedFiles([]);
    setResponseData([]);
  }, []);

  return (
    <>
      <InputFile isSingleFileAccept={true} isInputFileListVisible={true} />

      <Stack sx={{ flex: 1, justifyContent: "center" }}>
        <RemoveFooter selectedPages={selectedPages} setSelectedPages={setSelectedPages} />
      </Stack>

      <ShowResult responseData={responseData} />

      <Loader isLoading={isLoading} />

      <CoreButton
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        payload={{ pages: Object.keys(selectedPages).map((e) => Number(e)) }}
        setResponseData={setResponseData}
        disabled={!selectedFiles.length || isLoading}
      />
    </>
  );
};

export default Remove;
