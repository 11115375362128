/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Stack } from "@mui/material";

import { convertList } from "../common/constant";
import { useNav, usePdf } from "../../providers";
import { InputSelect, TConvertType } from "../common";

interface IConvertHeaderProps {
  convertForm: TConvertType;
  setConvertForm: Dispatch<SetStateAction<TConvertType>>;
  convertTo: TConvertType;
  setConvertTo: Dispatch<SetStateAction<TConvertType>>;
}

const ConvertHeader: FC<IConvertHeaderProps> = ({ convertForm, setConvertForm, convertTo, setConvertTo }) => {
  const { setAcceptFileType } = useNav();
  const { setSelectedFiles } = usePdf();

  useEffect(() => {
    if (convertForm) {
      let fileTypeObject = convertList[convertForm].fileTypes.reduce((ac, cv) => {
        return { ...ac, [cv.key]: cv.list };
      }, {});

      setAcceptFileType(fileTypeObject);

      setConvertTo(convertList[convertForm].accept[0]);
      setSelectedFiles([]);
    }
  }, [convertForm]);

  return (
    <Stack direction={{ sm: "column", md: "row" }} gap={2}>
      <InputSelect
        label="Convert"
        selectOptions={Object.values(convertList).map(({ id, name }) => ({ name, value: id }))}
        preSelectedValue={convertForm}
        handleOptionChange={(val) => setConvertForm(val as TConvertType)}
        labelWidth="5em"
      />

      <InputSelect
        label="To"
        selectOptions={convertList[convertForm].accept.map((id: TConvertType) => ({
          name: convertList[id].name,
          value: id,
        }))}
        preSelectedValue={convertTo}
        handleOptionChange={(val) => setConvertTo(val as TConvertType)}
        labelWidth="5em"
      />
    </Stack>
  );
};

export default ConvertHeader;
