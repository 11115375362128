import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";

import { IChildren } from "../component/common";

export interface IUser {
  id: string;
  firstName: string;
  lastName?: string;
  email: string;
  avatar?: string;
  RoleId: number;
}

interface IAuthContext {
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>>;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export const AuthProvider: FC<IChildren> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};
