/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import { useNav, usePdf } from "../../providers";
import PasswordFooter from "./PasswordFooter";
import { CoreButton, InputFile, Loader, ShowResult } from "../common";

const Password = () => {
  const { setActiveButton, setAcceptFileType } = useNav();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const [passwordText, setPasswordText] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any[]>([]);

  useEffect(() => {
    setActiveButton({
      name: "Password",
      value: "password",
      tooltip: "Encrypts PDF file/files with an user provided password",
    });
    setAcceptFileType({ "pdf/.pdf": [".pdf"] });
    setSelectedFiles([]);
    setResponseData([]);
  }, []);

  return (
    <>
      <PasswordFooter passwordText={passwordText} setPasswordText={setPasswordText} />
      <InputFile isSingleFileAccept={false} isInputFileListVisible={true} />

      <Stack sx={{ flex: 1, justifyContent: "center" }}></Stack>

      <ShowResult responseData={responseData} />

      <Loader isLoading={isLoading} />

      <CoreButton
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        payload={{ passwordText }}
        setResponseData={setResponseData}
        disabled={!selectedFiles.length || isLoading || !passwordText.length}
      />
    </>
  );
};

export default Password;
