/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import { useNav, usePdf } from "../../providers";
import WatermarkFooter from "./WatermarkFooter";
import { CoreButton, InputFile, Loader, ShowResult } from "../common";

const Watermark = () => {
  const { setActiveButton, setAcceptFileType } = useNav();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const [watermarkPosition, setWatermarkPosition] = useState<string>("top");
  const [watermarkText, setWatermarkText] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any[]>([]);

  useEffect(() => {
    setActiveButton({
      name: "Watermark",
      value: "watermark",
      tooltip: "Puts watermark on pages of PDF file/files with an user provided watermark text.",
    });
    setAcceptFileType({ "pdf/.pdf": [".pdf"] });
    setSelectedFiles([]);
    setResponseData([]);
  }, []);

  return (
    <>
      <WatermarkFooter
        watermarkPosition={watermarkPosition}
        setWatermarkPosition={setWatermarkPosition}
        watermarkText={watermarkText}
        setWatermarkText={setWatermarkText}
      />
      <InputFile isSingleFileAccept={false} isInputFileListVisible={true} />

      <Stack sx={{ flex: 1, justifyContent: "center" }}></Stack>

      <ShowResult responseData={responseData} />

      <Loader isLoading={isLoading} />

      <CoreButton
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        payload={{ watermarkPosition, watermarkText }}
        setResponseData={setResponseData}
        disabled={!selectedFiles.length || isLoading}
      />
    </>
  );
};

export default Watermark;
