import { Dispatch, FC, SetStateAction } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";

interface IConvertFooterProps {
  singlePdf: boolean;
  setSinglePdf: Dispatch<SetStateAction<boolean>>;
}

const ConvertFooter: FC<IConvertFooterProps> = ({ singlePdf, setSinglePdf }) => {
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value={singlePdf}
          onChange={() => setSinglePdf(!singlePdf)}
          control={<Checkbox checked={singlePdf} />}
          label="Single PDF"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );
};

export default ConvertFooter;
