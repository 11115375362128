import React, { FC } from "react";
import { InputSelect } from "../common";

interface IResizeDpiProps {
  selectedDpi: string;
  setSelectedDpi: React.Dispatch<React.SetStateAction<string>>;
}

const ResizeDpi: FC<IResizeDpiProps> = ({ selectedDpi, setSelectedDpi }) => {
  return (
    <InputSelect
      label="Select DPI"
      selectOptions={Array.from(Array(86).keys()).map((e) => ({
        name: (e + 15).toString(),
        value: (e + 15).toString(),
      }))}
      preSelectedValue={selectedDpi}
      handleOptionChange={setSelectedDpi}
      customStyle={{ mt: 2, justifyContent: "center" }}
    />
  );
};

export default ResizeDpi;
