import {
  AspectRatio,
  AutoAwesomeMotion,
  ChangeCircleOutlined,
  DeleteSweep,
  FileDownloadOutlined,
  InsertPageBreak,
  Merge,
  Password,
  PhotoSizeSelectSmall,
} from "@mui/icons-material";
import { TConvertType } from "./types";

export const drawerWidth = 200;

export const navButtons = [
  {
    name: "Convert",
    value: "convert",
    icon: ChangeCircleOutlined,
    route: "/",
    tooltip: "Converts from PDF to Image and Image to PDF.",
  },
  {
    name: "Extract",
    value: "extract",
    icon: AutoAwesomeMotion,
    route: "/extract",
    tooltip: "Extracts texts or images from PDF file/files",
  },
  {
    name: "Split",
    value: "split",
    icon: InsertPageBreak,
    route: "/split",
    tooltip: "Splits a .pdf file into two parts.",
  },
  {
    name: "Insert",
    value: "insert",
    icon: FileDownloadOutlined,
    route: "/insert",
    tooltip: "Inserts a PDF document into another .pdf file at a specific index.",
  },
  { name: "Merge", value: "merge", icon: Merge, route: "/merge", tooltip: "Merges two or more .pdf files" },
  {
    name: "Password",
    value: "password",
    icon: Password,
    route: "/password",
    tooltip: "Encrypts PDF file/files with an user provided password",
  },
  {
    name: "Watermark",
    value: "watermark",
    icon: PhotoSizeSelectSmall,
    route: "/watermark",
    tooltip: "Puts watermark on pages of PDF file/files with an user provided watermark text.",
  },
  {
    name: "Remove",
    value: "remove",
    icon: DeleteSweep,
    route: "/remove",
    tooltip: "Deletes a page or multiple pages from a PDF.",
  },
  {
    name: "Resize",
    value: "resize",
    icon: AspectRatio,
    route: "/resize",
    tooltip:
      "This feature is still experimental. It works best on image based PDFs. Select a low DPI like 25 to reduce the size of a PDF file.",
  },
];

export const convertList: {
  [key in TConvertType]: {
    id: string;
    name: string;
    accept: TConvertType[];
    fileTypes: { key: string; list: string[] }[];
  };
} = {
  // doc: { id: "doc", name: "doc/docx", accept: ["pdf"], fileTypes: [{ key: "doc/.doc", list: [".doc", ".docx"] }] },
  image: {
    id: "image",
    name: "image",
    accept: ["pdf"],
    fileTypes: [
      { key: "image/.png", list: [".png"] },
      { key: "image/.jpg", list: [".jpg"] },
      { key: "image/.jpeg", list: [".jpeg"] },
    ],
  },
  pdf: { id: "pdf", name: "pdf", accept: ["image"], fileTypes: [{ key: "pdf/.pdf", list: [".pdf"] }] },
  // text: { id: "text", name: "text", accept: ["pdf"], fileTypes: [{ key: "txt/.txt", list: [".txt"] }] },
};

export const extractList = [
  { name: "Text", value: "text" },
  { name: "Image", value: "image" },
];

export const positionList = [
  { name: "Top", value: "top" },
  { name: "Center", value: "center" },
  { name: "Bottom", value: "bottom" },
];
