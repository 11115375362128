import { createRoot } from "react-dom/client";
import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import Theme from "./theme/Theme";
import { AlertProvider, AuthProvider, NavProvider, PdfProvider } from "./providers";

createRoot(document.getElementById("root") as HTMLElement).render(
  <Theme>
    <AuthProvider>
      <AlertProvider>
        <NavProvider>
          <PdfProvider>
            <CssBaseline>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CssBaseline>
          </PdfProvider>
        </NavProvider>
      </AlertProvider>
    </AuthProvider>
  </Theme>
);
