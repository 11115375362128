/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Close, Upload } from "@mui/icons-material";

import { useAlert, useNav, usePdf } from "../../providers";

interface IInsertFileProps {
  isSingleFileAccept: boolean;
  isInputFileListVisible: boolean;
}

const MAX_FILES = 20;
const MAX_TOTAL_SIZE_MB = 40;
const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024;

const InsertFile: FC<IInsertFileProps> = ({ isSingleFileAccept, isInputFileListVisible }) => {
  const { acceptFileType } = useNav();
  const { addAlert } = useAlert();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFileType,
    onDrop: (inputFiles: File[]) => {
      let newFiles = inputFiles;

      if (!isSingleFileAccept) {
        // Include existing files when checking file count and total size
        newFiles = [...selectedFiles, ...inputFiles];
      }

      if (newFiles.length > MAX_FILES) {
        addAlert({ message: `Only the first ${MAX_FILES} files will be accepted.`, type: "error" });
        newFiles = newFiles.slice(0, MAX_FILES);
      }

      const totalSize = newFiles.reduce((acc, file) => acc + file.size, 0);

      if (totalSize > MAX_TOTAL_SIZE_BYTES) {
        addAlert({ message: `Total file size cannot exceed ${MAX_TOTAL_SIZE_MB} MB.`, type: "error" });
        return;
      }

      isSingleFileAccept
        ? setSelectedFiles([Object.assign(newFiles[0], { preview: URL.createObjectURL(newFiles[0]) })])
        : setSelectedFiles(newFiles.map((file: File) => Object.assign(file, { preview: URL.createObjectURL(file) })));
    },
  });

  return (
    <Box>
      {isInputFileListVisible && !isSingleFileAccept ? (
        <>
          <Typography sx={{ pt: 4 }}>
            {selectedFiles.length ? `Added File${isSingleFileAccept ? "" : "s"}` : "No file added"}
          </Typography>

          <Box sx={{ height: 200, maxHeight: 300, overflow: "scroll", border: "4px groove" }}>
            {selectedFiles.map((file, index) => {
              return (
                <Box key={file.name}>
                  {index + 1}. <Typography sx={{ display: "inline" }}>{file.name}</Typography>
                  <Button
                    onClick={() =>
                      setSelectedFiles([...selectedFiles.slice(0, index), ...selectedFiles.slice(index + 1)])
                    }
                  >
                    <Close />
                  </Button>
                </Box>
              );
            })}
          </Box>
        </>
      ) : (
        <></>
      )}
      {isSingleFileAccept && selectedFiles[0] && (
        <Box sx={{ border: "4px groove", px: 2 }}>{selectedFiles[0].name}</Box>
      )}

      <Button
        sx={{
          cursor: "pointer",
          border: "1px solid",
          p: 2,
          my: 2,
          textAlign: "center",
          borderRadius: 4,
          boxShadow: "gray 3px 3px 5px",
        }}
        {...getRootProps()}
        startIcon={<Upload />}
      >
        {selectedFiles.length ? "Change File" : `Upload Document for Insert`}
      </Button>
      <input {...getInputProps()} />
    </Box>
  );
};

export default InsertFile;
