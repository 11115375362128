/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import { useNav, usePdf } from "../../providers";
import ConvertHeader from "./ConvertHeader";
import ConvertFooter from "./ConvertFooter";
import { CoreButton, InputFile, Loader, ShowResult, TConvertType } from "../common";

const Convert = () => {
  const { setAcceptFileType } = useNav();
  const { selectedFiles, setSelectedFiles } = usePdf();

  const [convertForm, setConvertForm] = useState<TConvertType>("pdf");
  const [convertTo, setConvertTo] = useState<TConvertType>("image");
  const [singlePdf, setSinglePdf] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any[]>([]);

  useEffect(() => {
    setAcceptFileType({ "pdf/.pdf": [".pdf"] });
    setSelectedFiles([]);
    setResponseData([]);
  }, []);

  return (
    <>
      <ConvertHeader
        convertForm={convertForm}
        setConvertForm={setConvertForm}
        convertTo={convertTo}
        setConvertTo={setConvertTo}
      />

      <InputFile isSingleFileAccept={false} isInputFileListVisible={true} />

      <Stack sx={{ flex: 1, justifyContent: "center" }}>
        {convertTo === "pdf" && <ConvertFooter singlePdf={singlePdf} setSinglePdf={setSinglePdf} />}
      </Stack>

      <ShowResult responseData={responseData} />

      <Loader isLoading={isLoading} />

      <CoreButton
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        payload={{ convertForm, convertTo, ...(singlePdf && { singlePdf }) }}
        setResponseData={setResponseData}
        disabled={!selectedFiles.length || isLoading}
      />
    </>
  );
};

export default Convert;
