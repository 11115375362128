import { Dispatch, FC, SetStateAction } from "react";

import { extractList } from "../common/constant";
import { InputSelect } from "../common";

interface IExtractFooterProps {
  extractType: string;
  setExtractType: Dispatch<SetStateAction<string>>;
}

const ExtractFooter: FC<IExtractFooterProps> = ({ extractType, setExtractType }) => {
  return (
    <InputSelect
      label="Extract Texts/Images"
      selectOptions={extractList}
      preSelectedValue={extractType}
      handleOptionChange={(val) => setExtractType(val)}
      customStyle={{ mt: 2, justifyContent: "center" }}
    />
  );
};

export default ExtractFooter;
