import { FC, useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { IChildren } from "./types";
import { drawerWidth, navButtons } from "./constant";
import { useNav } from "../../providers";

const NavBar: FC<IChildren> = ({ children }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { activeButton, setActiveButton } = useNav();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box>
      <Box sx={{ height: 60 }}>
        <Typography
          sx={{
            height: 40,
            width: 180,
            textAlign: "center",
            lineHeight: "40px",
            fontSize: 28,
            bgcolor: "white",
            mx: "auto",
            mt: "15px",
            fontWeight: 900,
            color: theme.palette.primary.dark,
          }}
        >
          Menu
        </Typography>
      </Box>

      <Divider />

      <List>
        {navButtons.map(({ name, value, icon, route, tooltip }) => {
          const Icon = icon;
          return (
            <ListItem key={value} disablePadding>
              <ListItemButton
                onClick={() => {
                  setActiveButton({ name, value, tooltip });
                  navigate(route);
                }}
                sx={{
                  ...(activeButton.value === value
                    ? {
                        bgcolor: theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        "&:hover": {
                          bgcolor: theme.palette.primary.light,
                          color: theme.palette.primary.dark,
                        },
                      }
                    : {
                        bgcolor: "transparent",
                        color: theme.palette.primary.light,
                        "&:hover": {
                          bgcolor: "#CCC",
                          color: theme.palette.primary.dark,
                        },
                      }),
                  // textAlign: "right",
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  ml: 5,
                }}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Icon sx={{ ...(value === "insert" && { transform: "rotateZ(270deg)" }) }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    "& span": {
                      fontSize: "16px !important",
                      fontWeight: activeButton.value === value ? 900 : 500,
                    },
                    paddingRight: 4,
                  }}
                  primary={name}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
  const mobileDrawer = (
    <Box>
      <Typography sx={{ height: 40, textAlign: "center", lineHeight: "40px", fontSize: 24 }}>Menu</Typography>

      <Divider />

      <List>
        {navButtons.map(({ name, value, icon, route, tooltip }) => {
          const Icon = icon;
          return (
            <ListItem key={value} disablePadding>
              <ListItemButton
                onClick={() => {
                  setActiveButton({ name, value, tooltip });
                  navigate(route);
                }}
                sx={{
                  ...(activeButton.value === value
                    ? {
                        bgcolor: "lightgray",
                        color: theme.palette.primary.dark,
                        "&:hover": {
                          bgcolor: "lightgray",
                          color: theme.palette.primary.dark,
                        },
                      }
                    : {
                        bgcolor: "transparent",
                        color: theme.palette.text.primary,
                        "&:hover": {
                          bgcolor: "#CCC",
                          color: theme.palette.primary.dark,
                        },
                      }),
                  // textAlign: "right",
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  ml: 5,
                }}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Icon sx={{ ...(value === "insert" && { transform: "rotateZ(270deg)" }) }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    "& span": {
                      fontSize: "16px !important",
                      fontWeight: activeButton.value === value ? 900 : 500,
                    },
                    paddingRight: 4,
                  }}
                  primary={name}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "rgb(226,234,255)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ textAlign: "center", margin: "auto", color: "rgb(1,8,22)" }}
          >
            Welcome To EnigmaPDF
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="menu-list">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {mobileDrawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "primary.main",
              boxShadow:
                "2px 0px 4px -1px rgba(0, 0, 0, 0.3), 4px 0px 5px 0px rgba(0, 0, 0, 0.2), 1px 0px 10px 0px rgba(0, 0, 0, 0.2)",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <Toolbar />

        {children}
      </Box>
    </Box>
  );
};

export default NavBar;
