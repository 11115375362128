import { Dispatch, FC, SetStateAction } from "react";
import { Box, Button } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";

import { useNav } from "../../providers";
import { IFile } from "../common";

interface IInsertHeaderProps {
  mainInsertFile: IFile | null;
  setMainInsertFile: Dispatch<SetStateAction<IFile | null>>;
}

const InsertHeader: FC<IInsertHeaderProps> = ({ mainInsertFile, setMainInsertFile }) => {
  const { acceptFileType } = useNav();

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFileType,
    onDrop: (inputFile: File[]) =>
      setMainInsertFile(Object.assign(inputFile[0], { preview: URL.createObjectURL(inputFile[0]) })),
  });

  return (
    <>
      {mainInsertFile && <Box sx={{ border: "4px groove", px: 2 }}>{mainInsertFile.name}</Box>}

      <Button
        sx={{
          cursor: "pointer",
          border: "1px solid",
          p: 2,
          my: 2,
          textAlign: "center",
          borderRadius: 4,
          boxShadow: "gray 3px 3px 5px",
        }}
        {...getRootProps()}
        startIcon={<Upload />}
      >
        {mainInsertFile ? "Change File" : `Upload File`}
      </Button>
      <input {...getInputProps()} />
    </>
  );
};

export default InsertHeader;
